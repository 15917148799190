import posterImg from '../../../img/poster2025.jpg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

let HomePage = () => {

  return (
    <>
        <div className="homeContainer">
            <img className="home-page-image" alt="poker registration " src={posterImg}/>
            <div className="homePageButton">
                <Button className='register-btn'>
                    <Link className='nav-link' to={`/registration`}>
                        Register Now
                    </Link>
                </Button>
            </div>
            {/* <div className="promoContainer">
                <div className="promoVideo">
                    <iframe className="home-page-video" width="400" height="315" src="https://www.youtube.com/embed/OoxU_3lHcgw?si=ByiWTBhc64-CO4uM"></iframe>
                        <h6>SHORT PROMO VIDEO (60 SECONDS)</h6>
                </div>
                <div className="promoVideo">
                    <iframe className="home-page-video" width="400" height="315" src="https://www.youtube.com/embed/k09XsGDwzyI?si=nKqmp6g2swp84qkW"></iframe>
                        <h6>EXTENDED INFO VIDEO (2:40 MINUTES)</h6>
                </div>
            </div> */}
        </div>
    </>
  );

}

export default HomePage;