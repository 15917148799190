
let Registration = () => {

    return (
        <>
            <div className="registration-container">
                {/* <h4 className="mb-1">* Important* </h4> 
                <h5 className="mb-1">Event is <span className="important-txt">CASH ONLY</span></h5>
                <h5 className="mb-1">There will be <span className="important-txt">No ATM</span> at the venue</h5>
                <h5 className="mb-1">Please bring cash for <span className="important-txt">BONUS CHIPS</span> and <span className="important-txt">RAFFLE TICKETS</span></h5>
                <h5 className="mb-1"><span className="important-txt">NO REFUNDS</span> all donations are final</h5> */}
               
                {/* <h5 className="mb-1 registerText">  Zeffy provides <span className="important-txt">FREE</span> credit card processing to non-profit groups. Supporting the Zeffy platform is <span className="important-txt">OPTIONAL</span>. We <span className="important-txt">DO NOT</span> see if you contributed to Zeffy.</h5>
                <h5 className="mb-1 registerText" ><span className="important-txt">*Note*</span> If you <span className="important-txt">DO NOT</span> want to contribute to Zeffy:</h5>
                <h5 className="mb-1 registerText">1. In the Order Summary section, right of Support the 100% free platform we use! select <span className="important-txt">OTHER</span> in the dropdown box.</h5>
                <h5 className="mb-3 registerText">2. In the Contribution box, type the number <span className="important-txt">0 (zero).</span></h5>
                
                <h5 className="mb-1 registerText"><span className="important-txt bold">Please note: All donations are final and greatly appreciated.</span></h5> */}

                <h4 className="mb-1 registerText text-center">


                  <h5 className="mb-1 registerText text-center"> <span className="important-txt">PLEASE NOTE:</span> Zeffy provides credit card processing free of charge to non-profit groups and requests contributions to their company. We <span className="important-txt">DO NOT</span>  receive a portion of the contributions, nor do we have access to the contribution details.</h5>
                  <h5 className="mb-1 registerText text-center" ><span className="important-txt">*Note*</span> If you <span className="important-txt">DO NOT</span> want to contribute to Zeffy, please do the following:</h5>
                  <h5 className="mb-1 registerText">1. In the Order Summary section, right of Support the 100% free platform we use! select <span className="important-txt">OTHER</span> in the dropdown box.</h5>
                  <h5 className="mb-3 registerText ">2. In the Contribution box, type the number <span className="important-txt">0 (zero).</span></h5>
                
                 <h5 className="mb-1 registerText text-center"><span className="important-txt bold">Please note: All donations are final and greatly appreciated.</span></h5> 
            
                    
                </h4>

                <div className="zeffyThings1">
                    <iframe title='Donation form powered by Zeffy' className="zeffyThings2" src='https://www.zeffy.com/embed/ticketing/all-in-for-autism-charity-poker-championship' allowpaymentrequest allowTransparency="true">
                    </iframe>
                </div>
            </div>
        </>
    );

}

export default Registration;